@font-face {
    font-family: "Trade Gothic W01";
    src: url("/fonts/413d9688-00d4-47c4-bb55-656a0cd396e9.eot?#iefix");
    src: url("/fonts/413d9688-00d4-47c4-bb55-656a0cd396e9.eot?#iefix") format("eot"),
         url("/fonts/7b555e94-d57a-415e-a36e-1b0c5d3b90d2.woff2") format("woff2"),
         url("/fonts/c4aef0d4-bfcf-4790-acf5-909881f411e8.woff") format("woff"),
         url("/fonts/e741f29c-bc18-4343-bff3-db2465a0be3e.ttf") format("truetype"),
         url("/fonts/853e2a9b-4057-42a5-ad7e-0762cda0b13c.svg#853e2a9b-4057-42a5-ad7e-0762cda0b13c") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Trade Gothic W01";
    src: url("/fonts/2e9af08f-0faf-45e3-9767-78a700434af3.eot?#iefix");
    src: url("/fonts/2e9af08f-0faf-45e3-9767-78a700434af3.eot?#iefix") format("eot"),
         url("/fonts/d2e623ca-efc6-4557-ae55-ebb786065dcf.woff2") format("woff2"),
         url("/fonts/f51d24dd-8256-43c6-ba1a-6aeae9d3f262.woff") format("woff"),
         url("/fonts/955ee494-66b2-4eb2-8f19-bc3a088df16d.ttf") format("truetype"),
         url("/fonts/d5153ad4-7c93-4757-9147-640e69ec4801.svg#d5153ad4-7c93-4757-9147-640e69ec4801") format("svg");
    font-weight: 700;
    font-style: normal;
}
